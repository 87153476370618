import createDataContext from "./createDataContext";
import senseiApi from "../api/sensei";
import { Sensor } from "../utils/types";

export interface SensorState {
  devices: Sensor[];
  allDevices: Sensor[];
  kitPlusDevices: Sensor[];
  sensorReady: boolean;
}

interface SensorContext {
  state: SensorState;
  getMyDevices: () => void;
}

const sensorReducer = (state: SensorState, action: any) => {
  console.log(`sensorReducer, ${action.type}: `, action.payload);
  switch (action.type) {
    case "set-my-devices":
      return {
        ...state,
        allDevices: action.payload,
      };
    case "set-online-devices":
      return {
        ...state,
        devices: action.payload,
        sensorReady: true,
      };
    case "set-kit-plus-devices":
      return {
        ...state,
        kitPlusDevices: action.payload,
      };
    default:
      return state;
  }
};

const getMyDevices = (dispatch: any) => async () => {
  try {
    const response = await senseiApi.get("/get-my-devices", {
      withCredentials: true,
    });
    // add online status to devices
    let tempDevices = response.data.devices;
    tempDevices.forEach((device: any, index: number) => {
      let mostRecent = new Date(tempDevices[index].lastupdate);
      mostRecent.setSeconds(mostRecent.getSeconds() + 300);
      let now = new Date();
      if (mostRecent > now) {
        tempDevices[index].online = true;
      } else {
        tempDevices[index].online = false;
      }
    });
    tempDevices = tempDevices.sort((a: Sensor, b: Sensor) => {
      if (a.online && !b.online) return -1;
      if (!a.online && b.online) return 1;
      return 0;
    });
    let onlineDevices = tempDevices.filter((d: any) => d.online).slice(0, 4);
    dispatch({ type: "set-my-devices", payload: tempDevices });
    dispatch({ type: "set-online-devices", payload: onlineDevices });
    if (tempDevices.length > 4) {
      let kitPlusDevices = tempDevices.slice(4, tempDevices.length);
      dispatch({ type: "set-kit-plus-devices", payload: kitPlusDevices });
    }
    // add to reducer action if needed
    // if (state.devices === null || tempDevices.length > state.devices.length) {
    //   // tempDevices.sort((a: any, b: any) => (new Date(a.lastupdate) < new Date(b.lastupdate)) ? 1 : -1);
    //   tempDevices.forEach((device: any, index: number) => {
    //     tempDevices[index].weight = index;
    //   });
    // } else {
    //   tempDevices.forEach((device: Sensor) => {
    //     state.devices?.forEach((device2: Sensor) => {
    //       if (device.did === device2.did) {
    //         device.weight = device2.weight;
    //       }
    //     });
    //   });
    //   // uncomment to sort by weight
    //   // tempDevices.sort((a: any, b: any) => (a.weight > b.weight) ? 1 : -1);
    // }
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        return null;
      }
    }
  }
};

export const {
  Provider,
  Context,
}: {
  Provider: ({ children }: { children: any }) => JSX.Element;
  Context: React.Context<SensorContext>;
} = createDataContext(
  sensorReducer,
  {
    getMyDevices,
  },
  {
    devices: [],
    kitPlusDevices: [],
    sensorReady: false,
  }
);
