import createDataContext from "./createDataContext";
import senseiApi from "../api/sensei";
import {
  Conditions,
  Action,
  Location,
  RapidTest,
  Site,
  Zone,
} from "../utils/types";

export interface LocationState {
  locations: Array<Location> | null;
  sites: Array<Site> | null;
  zones: Array<Zone> | null;
  location: Location | null;
  site: Site | null;
  zone: Zone | null;
  authError: boolean;
  // move to RapidTest context
  conditionsInfo: Conditions;
  testStatus: "" | "running" | "success" | "error";
  eachTests: Array<RapidTest>;
}

interface LocationContext {
  state: LocationState;
  getLocations: () => Promise<void>;
  getSites: (lid: number) => Promise<void>;
  getZones: (lid: number) => Promise<void>;
  getCurrentLocation: (id: number) => Promise<void>;
  getCurrentSite: (id: number) => Promise<void>;
  getCurrentZone: (id: number) => Promise<void>;
  // move to RapidTest context
  setConditionsInfo: (conditions: Conditions) => void;
  clearError: () => void;
  // why is this here?
  getTests: () => void;
}

const locationReducer = (state: LocationState, action: Action) => {
  // console.log(`dispatch(${action.type}): `, action.payload);
  switch (action.type) {
    case "set-locations":
      return { ...state, locations: action.payload, sites: null, zones: null };
    case "set-sites":
      return { ...state, sites: action.payload, zones: null };
    case "set-zones":
      return { ...state, zones: action.payload };
    case "set-location":
      return {
        ...state,
        location: !!action.payload
          ? {
              lid: action.payload.lid,
              name: action.payload.name,
              address: action.payload.address,
              placeid: action.payload.placeid,
              owner: action.payload.owner,
              org: action.payload.org,
              created: action.payload.created,
            }
          : null,
      };
    case "set-site":
      return {
        ...state,
        site: !!action.payload
          ? {
              sid: action.payload.sid,
              name: action.payload.name,
              floorplan: action.payload.floorplan,
              location: action.payload.location,
              owner: action.payload.owner,
              org: action.payload.org,
              created: action.payload.created,
            }
          : null,
      };
    case "set-zone":
      return {
        ...state,
        zone: !!action.payload
          ? {
              zid: action.payload.zid,
              name: action.payload.name,
              site: action.payload.site,
              type: action.payload.type,
              volume: action.payload.volume,
              width: action.payload.width,
              length: action.payload.length,
              height: action.payload.height,
              peak: action.payload.peak,
              configuration: action.payload.configuration,
              owner: action.payload.owner,
              org: action.payload.org,
              created: action.payload.created,
            }
          : null,
      };

    case "set-test-status":
      return { ...state, testStatus: action.payload };
    case "set-conditions-info":
      return { ...state, conditionsInfo: action.payload };
    case "set-each-tests":
      return { ...state, eachTests: action.payload };
    case "auth-error":
      return { ...state, authError: true };
    case "clear-error":
      return { ...state, authError: false };
    default:
      return state;
  }
};

const getLocations = (dispatch: Function) => async () => {
  try {
    const response = await senseiApi.get("/locations?level=org", {
      withCredentials: true,
    });
    dispatch({ type: "set-locations", payload: response.data.data });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const getCurrentLocation = (dispatch: Function) => async (lid: number) => {
  if (lid === -1) {
    dispatch({ type: "set-location", payload: null });
    return;
  }
  try {
    const response = await senseiApi.get(`/locations/${lid}`, {
      withCredentials: true,
    });
    dispatch({ type: "set-location", payload: response.data.data[0] });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const getSites = (dispatch: Function) => async (lid: number) => {
  if (lid === -1) {
    dispatch({ type: "set-sites", payload: null });
    return;
  }
  try {
    const response = await senseiApi.get(`/sites?level=org&location=${lid}`, {
      withCredentials: true,
    });
    dispatch({ type: "set-sites", payload: response.data.data });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const getCurrentSite = (dispatch: Function) => async (sid: number) => {
  if (sid === -1) {
    dispatch({ type: "set-site", payload: null });
    return;
  }
  try {
    const response = await senseiApi.get(`/sites/${sid}`, {
      withCredentials: true,
    });
    dispatch({ type: "set-site", payload: response.data.data[0] });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const getZones = (dispatch: Function) => async (sid: number) => {
  if (sid === -1) {
    dispatch({ type: "set-zones", payload: null });
    return;
  }
  try {
    const response = await senseiApi.get(`/zones?site=${sid}`, {
      withCredentials: true,
    });
    dispatch({
      type: "set-zones",
      payload: response.data.data,
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const getCurrentZone = (dispatch: Function) => async (zid: number) => {
  if (zid === -1) {
    dispatch({ type: "set-zone", payload: null });
    return;
  }
  try {
    const response = await senseiApi.get(`/zones/${zid}`, {
      withCredentials: true,
    });
    dispatch({ type: "set-zone", payload: response.data.data[0] });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const getTests = (dispatch: Function) => async () => {
  try {
    const response = await senseiApi.get(`/tests`, {
      withCredentials: true,
    });
    dispatch({ type: "set-each-tests", payload: response.data.data });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: "auth-error" });
      }
    }
  }
};

const setConditionsInfo = (dispatch: Function) => (payload: Conditions) => {
  try {
    dispatch({ type: "set-conditions-info", payload: payload });
  } catch (error) {}
};

const clearError = (dispatch: Function) => () => {
  dispatch({ type: "clear-error" });
};

export const {
  Provider,
  Context,
}: {
  Provider: ({ children }: { children: any }) => JSX.Element;
  Context: React.Context<LocationContext>;
} = createDataContext(
  locationReducer,
  {
    getLocations,
    getSites,
    getZones,
    getCurrentLocation,
    getCurrentSite,
    getCurrentZone,
    clearError,

    // move to RapidTest context
    getTests,
    setConditionsInfo,
  },
  {
    locations: null,
    sites: null,
    zones: null,

    location: null,
    site: null,
    zone: null,

    // move to RapidTest context
    conditionsInfo: {
      email: "",
      interventions: "",
      cadr: "",
      hvac: -1,
      notes: "",
      did1: undefined,
      did2: undefined,
      did3: undefined,
      did4: undefined,
    },
    eachTests: [],
    testStatus: "",
    // why is this here?
    authError: false,
  }
);
