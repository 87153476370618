import createDataContext from "./createDataContext";
import senseiApi from "../api/sensei";
import { Kit } from "../utils/types";

export interface KitState {
  kit: Kit;
  allKits: Kit[];
}

interface KitContext {
  state: KitState;
  setKit: (kit: Kit) => void;
  getMyKits: () => void;
}

const kitReducer = (state: KitState, action: any) => {
  console.log(`sensorReducer, ${action.type}: `, action.payload);
  switch (action.type) {
    case "set-my-kits":
      if (state.allKits.length === 0)
        return {
          ...state,
          kit: action.payload[0],
          allKits: action.payload,
        };
      else
        return {
          ...state,
          allKits: action.payload,
        };
    case "set-current-kit":
      return {
        ...state,
        kit: action.payload,
      };
    default:
      return state;
  }
};

const setKit = (dispatch: any) => (kit: Kit) => {
  dispatch({ type: "set-current-kit", payload: kit });
};

const getMyKits = (dispatch: any) => async () => {
  try {
    const response = await senseiApi.get("/kits", {
      withCredentials: true,
    });
    dispatch({ type: "set-my-kits", payload: response.data.data });
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 401) {
        return null;
      }
    }
  }
};

export const {
  Provider,
  Context,
}: {
  Provider: ({ children }: { children: any }) => JSX.Element;
  Context: React.Context<KitContext>;
} = createDataContext(
  kitReducer,
  {
    setKit,
    getMyKits,
  },
  {
    kit: null,
    allKits: [],
  }
);
