export const paths = {
  root: "/",
  auth: "/auth",
  login: "/auth/login",
  signup: "/auth/signup",
  setNewPassword: "/auth/set-new-password",
  setNewPasswordToken: "/auth/set-new-password/:token?",
  claimCode: "/claim-code/:claimCode",
  dashboard: "/dashboard",
  details: "/device/:did",
  profile: "/profile",
  account: "/account",
  settings: "/settings",
  support: "https://www.poppy.com/contact",
  home: "/home",
  newLocation: "/rapid-test/new",
  rapidTest: "/rapid-test/:tid",
  conditionsInfo: "/rapid-test/conditions-info",
  // this is not a lid, but a zid. page is just called /location.
  location: "/location/:lid",
};
