import React, { useEffect, useContext } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import "./App.css";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";

import { Context as SensorContext } from "./context/SensorContext";
import { Context as TestContext } from "./context/TestContext";
import { Context as AuthContext } from "./context/AuthContext";
import { Context as KitContext } from "./context/KitContext";
import { Context as SettingsContext } from "./context/SettingsContext";
import { Context as LocationContext } from "./context/LocationContext";

import Nav from "./components/Nav";
import PrivateRoute, {
  PrivateRouteProps,
} from "./components/PrivateRoute/PrivateRoute";

import { paths } from "./utils/const";

import styles from "./main.module.scss";

const Home = React.lazy(() => import("./screens/Home"));
const NewLocation = React.lazy(() => import("./screens/NewLocation"));
const ConditionsInfo = React.lazy(() => import("./screens/ConditionsInfo"));
const RapidTest = React.lazy(() => import("./screens/RapidTest"));
const Account = React.lazy(() => import("./screens/Account"));
const Location = React.lazy(() => import("./screens/Location"));
const Settings = React.lazy(() => import("./screens/Settings"));

let translationPath = "/locales/{{lng}}/{{ns}}.json";
// if (process.env.NODE_ENV === "development")
//   translationPath = 'http://localhost:3000/locales/{{lng}}/{{ns}}.json';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: translationPath,
    },
  });

function Main(props: any) {
  const loc = useLocation();
  const history = useHistory();

  const { state: authState, setRedirectPath } = useContext(AuthContext);
  const { getMyKits } = useContext(KitContext);
  const { refreshSettings } = useContext(SettingsContext);
  const { getMyDevices } = useContext(SensorContext);
  const { state: testState } = useContext(TestContext);
  const { getTests } = useContext(LocationContext);

  useEffect(() => {
    if (authState.signin === false) {
      history.push(paths.login);
    }
  }, [authState, history]);

  // request Notification permission
  useEffect(() => {
    async function requestPermissions() {
      let wakeLock = null;
      if (!("Notification" in window)) {
        console.log("Browser does not support desktop notification");
      } else {
        if (!!authState.signin) Notification.requestPermission();
      }
      if (!!authState.signin) {
        try {
          if ("wakeLock" in navigator) {
            wakeLock = await navigator?.wakeLock.request("screen");
            console.log("Wake lock is active: ", wakeLock?.type);
          } else {
            console.log("Wake lock is not supported by this browser.");
          }
        } catch {}
      }
    }
    requestPermissions();
  }, [authState?.signin]);

  // replace notification on diffusion on/off with notification upon test completion
  // check testState for test completion
  useEffect(() => {
    // console.log("testState: ", testState);
  }, [testState]);

  useEffect(() => {
    // checkSignin();
    refreshSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // checkSignin();
    function tick() {
      if (!authState.signin) {
      } else {
        getMyKits();
        getMyDevices();
        getTests();
      }
    }
    tick();
    const timer = setInterval(() => {
      tick();
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.signin]);

  const defaultPrivateRouteProps: PrivateRouteProps = {
    isAuthenticated: !!authState.signin,
    authenticationPath: "/auth/login",
    redirectPath: loc.pathname,
    setRedirectPath: (path: string) => {
      setRedirectPath(path);
    },
  };

  return (
    <div className={styles.main}>
      <Nav />
      <main className={styles.content}>
        <Switch>
          <PrivateRoute {...defaultPrivateRouteProps} path={paths.home}>
            <Home {...props} />
          </PrivateRoute>
          <PrivateRoute {...defaultPrivateRouteProps} path={paths.newLocation}>
            <NewLocation {...props} />
          </PrivateRoute>
          <PrivateRoute
            {...defaultPrivateRouteProps}
            path={paths.conditionsInfo}
          >
            <ConditionsInfo {...props} />
          </PrivateRoute>
          <PrivateRoute {...defaultPrivateRouteProps} path={paths.rapidTest}>
            <RapidTest {...props} />
          </PrivateRoute>
          <PrivateRoute {...defaultPrivateRouteProps} path={paths.account}>
            <Account {...props} />
          </PrivateRoute>
          <PrivateRoute {...defaultPrivateRouteProps} path={paths.location}>
            <Location {...props} />
          </PrivateRoute>
          <PrivateRoute {...defaultPrivateRouteProps} path={paths.settings}>
            <Settings {...props} />
          </PrivateRoute>
          <Redirect to={paths.auth} />
        </Switch>
      </main>
    </div>
  );
}

export default Main;
