import createDataContext from "./createDataContext";
import senseiApi from "../api/sensei";
import { Action, RapidTest } from "../utils/types";

interface TestState {
  tid: number;
  rapidTest: RapidTest;
  chartData: [];
  isLoading: boolean;
}

interface TestContext {
  state: TestState;
  fetchChartData: (did: number) => void;
  fetchRapidTest: (tid: number) => void;
  setCurrentRapidTest: (tid: number) => void;
  setIsLoading: (loading: boolean) => void;
}

function getQueryString(data = {}) {
  return Object.entries(data)
    .map(
      ([key, value]: [key: any, value: any]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
}

const testReducer = (state: TestState, action: Action) => {
  // console.log(`${action.type}: `, action.payload);
  switch (action.type) {
    case "set-test-id":
      return { ...state, tid: action.payload };
    case "set-rapid-test":
      return { ...state, rapidTest: action.payload };
    case "fetch-chart-data":
      return { ...state, chartData: action.payload };
    case "set-is-loading":
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

const setCurrentRapidTest = (dispatch: any) => async (tid: string) => {
  try {
    dispatch({ type: "set-test-id", payload: tid });
  } catch (error: any) {}
};

const fetchChartData = (dispatch: any) => async (did: number) => {
  try {
    if (did > 0) {
      const response = await senseiApi.get(
        `get-minute-averages/${did}?page=${0}`,
        { withCredentials: true }
      );
      if (response.data?.data?.length) {
        dispatch({
          type: "fetch-chart-data",
          payload: response.data.data,
        });
      }
    }
  } catch (error: any) {}
};

const fetchRapidTest = (dispatch: any) => async (tid: number) => {
  try {
    if (tid > 0) {
      const response = await senseiApi.get(`tests/${tid}`, {
        withCredentials: true,
      });
      dispatch({
        type: "set-rapid-test",
        payload: {
          ...response?.data?.data[0],
        },
      });
    }
  } catch (error: any) {}
};

const setIsLoading = (dispatch: any) => async (loading: true) => {
  try {
    dispatch({ type: "set-test-id", payload: loading });
  } catch (error: any) {}
};

export const {
  Provider,
  Context,
}: {
  Provider: ({ children }: { children: any }) => JSX.Element;
  Context: React.Context<TestContext>;
} = createDataContext(
  testReducer,
  {
    fetchChartData,
    fetchRapidTest,
    setCurrentRapidTest,
    setIsLoading,
  },
  {
    tid: -1,
    rapidTest: {} as RapidTest,
    chartData: [],
    isLoading: false,
  } as TestState
);
