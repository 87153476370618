import createDataContext from "./createDataContext";
import Cookies from "universal-cookie";
import i18n from "i18next";

const cookies = new Cookies();

export interface SettingsState {
  hideOffline: boolean;
  hideEach: boolean;
  tempScale: string;
  language: string;
}

interface SettingsContext {
  state: SettingsState;
  displayOffline: (displayBoolean: boolean) => void;
  toggleEach: (displayBoolean: boolean) => void;
  setTempScale: (scale: string) => void;
  setLanguage: (id: number) => void;
  refreshSettings: () => void;
}

const settingsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "hide_offline":
      return { ...state, hideOffline: action.payload };
    case "hide_each":
      return { ...state, hideEach: action.payload };
    case "set_temp_scale":
      return { ...state, tempScale: action.payload };
    case "set_language":
      return { ...state, language: action.payload };
    case "refresh_settings":
      return {
        hideOffline: action.payload.hide_offline,
        hideEach: action.payload.hide_each,
        tempScale: action.payload.set_temp_scale,
        language: action.payload.set_language,
      };
    default:
      return state;
  }
};

const displayOffline = (dispatch: any) => (displayBool: Boolean) => {
  let expireDate = new Date();
  expireDate.setFullYear(expireDate.getFullYear() + 5);
  cookies.set("hide_offline", displayBool, {
    sameSite: "strict",
    expires: expireDate,
  });
  dispatch({ type: "hide_offline", payload: displayBool });
};

const toggleEach = (dispatch: any) => (displayBool: boolean) => {
  let expireDate = new Date();
  expireDate.setFullYear(expireDate.getFullYear() + 5);
  cookies.set("hide_each", displayBool, {
    sameSite: "strict",
    expires: expireDate,
  });
  dispatch({ type: "hide_each", payload: displayBool });
};

const setTempScale = (dispatch: any) => (scale: string) => {
  let expireDate = new Date();
  expireDate.setFullYear(expireDate.getFullYear() + 5);
  cookies.set("set_temp_scale", scale, {
    sameSite: "strict",
    expires: expireDate,
  });
  dispatch({ type: "set_temp_scale", payload: scale });
};

const setLanguage = (dispatch: any) => (language: string) => {
  let expireDate = new Date();
  expireDate.setFullYear(expireDate.getFullYear() + 5);
  cookies.set("set_language", language, {
    sameSite: "strict",
    expires: expireDate,
  });
  i18n.changeLanguage(language);
  dispatch({ type: "set_language", payload: language });
};

const refreshSettings = (dispatch: any) => () => {
  let tempScaleCookie = cookies.get("set_temp_scale")
    ? cookies.get("set_temp_scale")
    : "F";
  let hideOfflineCookie =
    typeof cookies.get("hide_offline") !== "undefined"
      ? cookies.get("hide_offline") === "true"
      : true;
  let hideEachCookie =
    typeof cookies.get("hide_each") !== "undefined"
      ? cookies.get("hide_each") === "true"
      : true;
  let languageCookie = cookies.get("set_language")
    ? cookies.get("set_language")
    : "en";
  i18n.changeLanguage(languageCookie);
  dispatch({
    type: "refresh_settings",
    payload: {
      hide_offline: hideOfflineCookie,
      hide_each: hideEachCookie,
      set_temp_scale: tempScaleCookie,
      set_language: languageCookie,
    },
  });
};

export const {
  Provider,
  Context,
}: {
  Provider: ({ children }: { children: any }) => JSX.Element;
  Context: React.Context<SettingsContext>;
} = createDataContext(
  settingsReducer,
  { setTempScale, setLanguage, displayOffline, toggleEach, refreshSettings },
  { tempScale: "F", hideOffline: true, hideEach: true, language: "en" }
);
