import createDataContext from "./createDataContext";
import senseiApi from "../api/sensei";
// import { navigate } from '../navigationRef';

const authReducer = (state: any, action: any) => {
  switch (action.type) {
    case "add_error":
      // console.log("add_err action: ", action.payload)
      return { ...state, errorMessage: action.payload };
    case "signin":
      // console.log("signin reducer action: ")
      return { ...state, errorMessage: "", signin: true };
    case "update_user":
      return { ...state, user: action.payload };
    case "clear_error":
      return { ...state, errorMessage: "", responseMessage: "" };
    case "add_response":
      return { ...state, responseMessage: action.payload };
    case "clear_response":
      return { ...state, responseMessage: "" };
    case "signout":
      // console.log("signout action: ")
      return { ...state, errorMessage: "", signin: false, user: {} };
    case "set_redirect":
      return { ...state, redirectPath: action.payload };
    default:
      return state;
  }
};

const checkSignin = (dispatch: any) => async () => {
  try {
    // console.log("checkSignIn: ");
    const response = await senseiApi.get("/account", { withCredentials: true });
    // console.log("checkSignIn respnse: ", response);
    if (!response.data.error) {
      dispatch({ type: "update_user", payload: response.data });
      dispatch({ type: "signin" });
    } else {
      dispatch({ type: "signout" });
    }
  } catch (err: any) {
    if (
      err.hasOwnProperty("message") &&
      err.message === "Request failed with status code 401"
    ) {
      dispatch({ type: "signout" });
    }
    // dispatch({ type: 'signout' });
  }
};

const clearResponse = (dispatch: any) => () => {
  dispatch({ type: "clear_response" });
};

const setResponse = (dispatch: any) => (message: string) => {
  dispatch({ type: "add_response", payload: message });
};

const clearError = (dispatch: any) => () => {
  dispatch({ type: "clear_error" });
};

const setError = (dispatch: any) => (message: string) => {
  dispatch({ type: "add_error", payload: message });
};

const signup =
  (dispatch: any) =>
  async ({
    username,
    email,
    password,
  }: {
    username: any;
    email: any;
    password: any;
  }) => {
    try {
      // const formData = new FormData();
      // formData.append("username", username);
      // formData.append("email", email);
      // formData.append("password", password);
      // console.log(username);
      const response = await senseiApi.post(
        "/register",
        getQueryString({ username, email, password })
      );
      // dispatch({ type: 'signin', payload: response.data.token });
      if (
        response.data.message &&
        response.data.message === "registration-success"
      ) {
        dispatch({ type: "add_response", payload: "registration-success" });
      } else {
        dispatch({ type: "add_error", payload: response.data.message });
      }
      // navigate('TrackList');
    } catch (err) {
      dispatch({ type: "add_error", payload: "signup-error" });
    }
  };

const signin =
  (dispatch: any) =>
  async ({ username, password }: { username: any; password: any }) => {
    try {
      const response = await senseiApi.post(
        "/login",
        getQueryString({ username, password }),
        { withCredentials: true, maxRedirects: 0 }
      );
      // console.log("signin response: ", response.data)
      if (response.data.message && response.data.message === "login-success") {
        dispatch({ type: "signin" });
      } else {
        // console.log("signin add error: ", response.data)
        dispatch({ type: "add_error", payload: response.data.message });
      }
    } catch (err: any) {
      // console.log("signin", err.message);
      dispatch({ type: "add_error", payload: "login-failed" });
    }
  };

const signout = (dispatch: any) => async () => {
  try {
    // const response = await senseiApi.get('/logout', { withCredentials: true });
    await senseiApi.get("/logout", { withCredentials: true });
  } catch (err: any) {
    // console.log("signout", err.message);
  }
  dispatch({ type: "signout" });
};

const updatePassword =
  (dispatch: any) => async (oldPassword: string, newPassword: string) => {
    try {
      // console.log(`oldPassword: ${oldPassword}, newPassword: ${newPassword}`)
      const response = await senseiApi.post(
        "/account",
        getQueryString({ password: oldPassword, new_password: newPassword }),
        { withCredentials: true }
      );
      // console.log("updatePassword response: ", response.data)
      if (response.data.message && response.data.message === "success") {
        dispatch({ type: "add_response", payload: response.data.message });
      } else {
        // console.log("update password add error: ", response.data)
        dispatch({ type: "add_error", payload: response.data.message });
      }
    } catch (err: any) {
      // console.log("update password err.msg: ", err);
      dispatch({ type: "add_error", payload: "update-password-failed" });
    }
  };

const updateEmail =
  (dispatch: any) => async (password: string, newEmail: string) => {
    try {
      const response = await senseiApi.post(
        "/account",
        getQueryString({ password: password, email: newEmail }),
        { withCredentials: true }
      );
      // console.log("updateEmail response: ", response.data)
      if (response.data.message && response.data.message === "success") {
        // dispatch({ type: 'update' });
      } else {
        // console.log("update email add error: ", response.data)
        dispatch({ type: "add_error", payload: response.data.message });
      }
    } catch (err: any) {
      // console.log("update email err.msg: ", err.message);
      dispatch({ type: "add_error", payload: "update-password-failed" });
    }
  };

// const sendResetPassword = (dispatch: any) => async (password: string) => {
//     let data = {
//       email
//     };
//     clearError();
//     fetch("https://sensei.pierasystems.com/api/reset-password", {
//       method: "POST",
//       body: new URLSearchParams(data)
//     }).then(res => {
//       return res.json()
//     }).then(body => {
//       // "password-reset"
//       console.log(body.message);
//       if (body.hasOwnProperty("message") && body.message === "password-reset") {
//         setSuccess(true);
//       } else {
//         setError("error");
//       }
//     });
// }

const setRedirectPath = (dispatch: any) => async (redirectTo: string) => {
  dispatch({ type: "set_redirect", payload: redirectTo });
};

function getQueryString(data = {}) {
  return Object.entries(data)
    .map(
      ([key, value]: [key: any, value: any]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
}

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    signup,
    signin,
    clearError,
    checkSignin,
    signout,
    setError,
    clearResponse,
    setResponse,
    updatePassword,
    updateEmail,
    setRedirectPath,
  },
  {
    signin: null,
    errorMessage: "",
    responseMessage: "",
    user: {},
    redirectPath: "",
  }
);
